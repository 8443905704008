<template>
  <div style = "height: 100%;" :class="{'app-show': width < 1200}">
    <div class="login" v-if="width > 1200"><a @click="$router.push('/login')">后台管理</a></div>
    <div class="swiper-container down_pc" id="downCont">
      <div class="swiper-wrapper">
        <div class="swiper-slide down_slide4">
          <div class="slideCont">
            <h2>满电行</h2>
            <div class="down_mes">
              <p>微信支付宝扫一扫就能充电</p>
              <p>为您精确定位导航充电站</p>
              <p>下载客户端享受更多功能</p>
              <p>大数据信息爱车交流无障碍</p>
            </div>
            <div class="down_dbox">
              <div class="down_btns">
                <button class="androidDown"><i class="iconfont icon-anzhuo"></i><a href="http://www.mdxxny.com/ApKFile/mdx_yyb.apk">安卓版下载</a></button>
                <button class="iosDown"><i class="iconfont icon-pingguo"></i><a href="javascript:;">iOS暂无下载</a></button>
              </div>
              <div class="down_ewm">
                <img src="@/views/appshow/picture/ewm.png" />
              </div>
            </div>
            <div class="down_pic">
              <img src="@/views/appshow/picture/phone_pc.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>Copyright © 2011-{{new Date().getFullYear()}} <a :href="globalConfig.companyUrl" target="_blank">{{ globalConfig.company }}</a> 版权所有</p>
      <p>
        公网备案
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ globalConfig.icpNum }}</a>
      </p>
    </div>
  </div>
</template>

<script>
  import globalConfig from '@/config'
  export default {
    data () {
      return {
        width: 0,
        globalConfig,
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.width = window.innerWidth
        window.onresize = () => {
          this.width = window.innerWidth
        }
      })
    },
    methods: {
    }
  }
</script>

<style>
    @import url(./css/base.css);
    @import url(./css/downcont.css);
    @import url(./css/public.css);
    @import url(./css/swiper.min.css);
</style>
<style lang="less" scoped>
.footer{
  position: absolute;
  bottom: 10px;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  a{
    color: #fff;
  }
  a:hover{
    color: #ddd;
  }
}
.app-show{
  .slideCont{
    h2{
      font-size: 28px;
      margin-top: 0;
    }
  }
  .down_mes{
    font-size: 16px;
  }
  .swiper-slide {
    .down_dbox, .down_pic{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .down_dbox{
      width: 50%;
      left: auto;
      margin-top: 0;
      right: 5%;
      z-index: 10;
    }
    .down_btns{
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      button{
        font-size: 14px;
        width: 80%;
        height: 30px;
        line-height: 30px;
        display: inline-block;
      }
    }
    .down_pic {
      left: 8%;
      z-index: 9;
      img{
        width: 150px;
      }
    }
    .down_ewm img{
      width: 120px;
      height: 120px;
      display: block;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 750px) and (max-width: 980px) {
    .swiper-slide{
      .slideCont{
        h2{
          font-size: 32px;
          margin-top: 0;
        }
      }
      .down_mes{
        font-size: 24px;
      }
      .down_btns{
        button{
          width: 60%;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
      .down_pic{
        img{
          width: 300px;
        }
      }
      .down_ewm img{
        width: 200px;
        height: 200px;
      }
    }
  }
  @media screen and (min-width: 376px) and (max-width: 750px) {
    .swiper-slide {
      .down_dbox, .down_pic{
        top: 40%;
        transform: translateY(-40%);
      }
      .down_btns{
        button{
          width: 70%;
        }
      }
      .down_pic{
        img{
          width: 160px;
        }
      }
    }
  }
}
</style>
